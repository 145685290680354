import Vue from "vue"
import VueRouter from "vue-router"
import PublicRouter from "./pub/pub.js"
import AdminRouter from "./auth/admin.js"
import CustomerRouter from "./auth/customer"

Vue.use(VueRouter)

const baseRoutes = []
const routes = baseRoutes.concat(PublicRouter, AdminRouter, CustomerRouter)

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
})

/* const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
} */

export default router
