import axios from "axios"
import base from "@/base.js"

const service = axios.create({
	//url = base url + reqeust url
	baseURL: base.apiServer, //配置请求超时时间30秒

	timeout: 30 * 1000
})

export default service
