import axios from "@/api/baseRequest.js"

//列出轮播图
export const apiListCarousel = function() {
	return axios.get("/api/v1/pub/carousel/list")
}

//根据ID获取轮播图
export const apiGetCarouselById = function(id) {
	return axios.get("api/v1/pub/carousel/get_by_id", {
		params: {
			id: id
		}
	})
}
