import AdminHome from "../../views/auth/pc/admin/Home.vue"
import Desktop from "../../views/auth/pc/admin/Desktop.vue"

export default [
	{
		path: "/admin",
		name: "Auth",
		component: AdminHome,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/desktop",
		name: "Desktop",
		component: Desktop,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/category/show_in_home",
		name: "ShowCategoryInHome",
		component: () => import("@/views/auth/pc/admin/category/ShowInHome.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/carousel/list",
		name: "ListCarousel",
		component: () => import("@/views/auth/pc/admin/carousel/List.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/carousel/add",
		name: "AddCarousel",
		component: () => import("@/views/auth/pc/admin/carousel/Add.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/carousel/edit",
		name: "EditCarousel",
		component: () => import("@/views/auth/pc/admin/carousel/Edit.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/product/list_hot_sale",
		name: "ListHotSale",
		component: () => import("@/views/auth/pc/admin/product/ListHotSale.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/product/list_boutique",
		name: "ListBoutique",
		component: () => import("@/views/auth/pc/admin/product/ListBoutique.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/product/list_new_star",
		name: "ListNewStar",
		component: () => import("@/views/auth/pc/admin/product/ListNewStar.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/product/list",
		name: "ListProduct",
		component: () => import("@/views/auth/pc/admin/product/List.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/product/add",
		name: "AddProduct",
		component: () => import("@/views/auth/pc/admin/product/Add.vue"),
		meta: {
			requiresAuth: true
		}
	},
	/* 	{
		path: "/admin/pc/product/test_add",
		name: "TestAddProduct",
		component: () => import("@/views/auth/pc/admin/product/TestAdd.vue"),
		meta: {
			requiresAuth: true
		}
	}, */
	{
		path: "/admin/pc/product/edit",
		name: "EditProduct",
		component: () => import("@/views/auth/pc/admin/product/Edit.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/stock/list",
		name: "ListStock",
		component: () => import("@/views/auth/pc/admin/stock/List.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/stock/add",
		name: "AddStock",
		component: () => import("@/views/auth/pc/admin/stock/Add.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/stock/edit",
		name: "EditStock",
		component: () => import("@/views/auth/pc/admin/stock/Edit.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/category/list",
		name: "ListCategory",
		component: () => import("@/views/auth/pc/admin/category/List.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/category/add",
		name: "AddCategory",
		component: () => import("@/views/auth/pc/admin/category/Add.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/category/edit",
		name: "EditCategory",
		component: () => import("@/views/auth/pc/admin/category/Edit.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/gateway/sms",
		name: "SmsGateway",
		component: () => import("@/views/auth/pc/admin/gateway/SMS.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/strategy/sms",
		name: "SmsStrategy",
		component: () => import("@/views/auth/pc/admin/strategy/SMS.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/agreement/edit_user_agreement",
		name: "EditUserAgreement",
		component: () => import("@/views/auth/pc/admin/agreement/EditUserAgreement.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/admin/pc/agreement/edit_privacy_policy",
		name: "EditPrivacyPolicy",
		component: () => import("@/views/auth/pc/admin/agreement/EditPrivacyPolicy.vue"),
		meta: {
			requiresAuth: true
		}
	}
]
