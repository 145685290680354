<template>
	<div>
		<!-- 头部组件 -->
		<index-header></index-header>
		<index-neck></index-neck>
		<router-view></router-view>
		<!-- 尾部组件 -->
		<index-footer></index-footer>
	</div>
</template>

<script>
import IndexHeader from "./common/Header"
import IndexNeck from "./common/Neck"
import IndexFooter from "./common/Footer"
export default {
	//注册组件
	components: {
		IndexHeader,
		IndexNeck,
		IndexFooter
	},
	data() {
		return {}
	},
	methods: {},
	mounted() {}
}
</script>

<style lang="scss" scoped></style>
