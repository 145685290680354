<template>
	<div>
		<el-menu default-active="" @open="handleOpen" @close="handleClose" background-color="#e3e9f1" text-color="#000" active-text-color="#1e82e8">
			<div v-for="(item, index) in sideBarData" :key="index">
				<el-submenu v-if="item.children" :index="index + '-' + item.url">
					<template slot="title">
						<i :class="item.icon"></i>
						<span slot="title">{{ item.label }}</span>
					</template>
					<el-menu-item-group>
						<el-menu-item
							v-for="(subItem, subIndex) in item.children"
							:key="subIndex"
							:index="index + '-' + subIndex + '-' + subItem.url"
							@click.native="clickSidebarMenu(subItem.label, subItem.url, subItem.open_tab)"
						>
							<i :class="subItem.icon"></i>
							<span slot="title">{{ subItem.label }}</span>
						</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
				<el-menu-item v-else :index="index + '-' + item.url" @click.native="clickSidebarMenu(item.label, item.url, item.open_tab)">
					<i :class="item.icon"></i>
					<span slot="title">{{ item.label }}</span>
				</el-menu-item>
			</div>
		</el-menu>
	</div>
</template>

<script>
export default {
	data() {
		return {
			sideBarData: [
				{
					label: "首页设置",
					icon: "el-icon-s-home",
					children: [
						{
							label: "显示分类",
							url: "/admin/pc/category/show_in_home",
							icon: "el-icon-more-outline",
							open_tab: true
						},
						{
							label: "管理轮播图",
							url: "/admin/pc/carousel/list",
							icon: "el-icon-video-camera",
							open_tab: true
						},
						{
							label: "管理热销",
							url: "/admin/pc/product/list_hot_sale",
							icon: "el-icon-trophy-1",
							open_tab: true
						},
						{
							label: "管理精选",
							url: "/admin/pc/product/list_boutique",
							icon: "el-icon-present",
							open_tab: true
						},
						{
							label: "管理新品",
							url: "/admin/pc/product/list_new_star",
							icon: "el-icon-shopping-bag-2",
							open_tab: true
						}
					]
				},
				{
					label: "商城管理",
					icon: "el-icon-s-shop",
					children: [
						{
							label: "管理产品",
							url: "/admin/pc/product/list",
							icon: "el-icon-box",
							open_tab: true
						},
						{
							label: "管理库存",
							url: "/admin/pc/stock/list",
							icon: "el-icon-office-building",
							open_tab: true
						},
						{
							label: "管理分类",
							url: "/admin/pc/category/list",
							icon: "el-icon-s-order",
							open_tab: true
						}
					]
				},
				{
					label: "系统设置",
					icon: "el-icon-s-tools",
					children: [
						{
							label: "短信网关",
							url: "/admin/pc/gateway/sms",
							icon: "el-icon-s-promotion",
							open_tab: true
						},
						{
							label: "短信策略",
							url: "/admin/pc/strategy/sms",
							icon: "el-icon-guide",
							open_tab: true
						},
						{
							label: "用户协议",
							url: "/admin/pc/agreement/edit_user_agreement",
							icon: "el-icon-s-order",
							open_tab: true
						},
						{
							label: "隐私政策",
							url: "/admin/pc/agreement/edit_privacy_policy",
							icon: "el-icon-s-custom",
							open_tab: true
						}
					]
				}
			]
		}
	},
	//定义方法
	methods: {
		handleOpen(key, keyPath) {
			//console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			//console.log(key, keyPath);
		},
		clickSidebarMenu(label, url, open_tab) {
			//console.log(label, url);
			this.$parent.openTab(label, url, open_tab)
		}
	}
}
</script>

<style lang="scss" scoped></style>
