<template>
	<div>
		<div class="carousel-container">
			<el-carousel height="25vw">
				<el-carousel-item v-for="item in carouselList" :key="item.id">
					<a :href="item.target_url" target="_blank">
						<img :src="apiServer + item.image_url" style="width:100%;height:25vw" />
					</a>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div style="background-color:#F5F5F5;padding-bottom: 30px;">
			<div class="product-container">
				<el-row v-if="hotSaleList.length > 0">
					<h2>热销产品</h2>
					<template v-for="(product, index) in hotSaleList">
						<div
							class="product-list"
							:style="index == 4 || index == 9 ? 'margin-right:0px;' : ''"
							:key="'hot_sale_' + product.id"
							@click="showProductDetail(product.id)"
						>
							<p style="margin: 0px;">
								<img :src="apiServer + product.main_image.path" :alt="product.name" />
							</p>
							<p class="product-name" :title="product.name">{{ product.name }}</p>
							<p class="product-price">￥{{ product.price / 100 }}</p>
							<p v-if="product.least > 1" class="product-limit">[&nbsp;{{ product.least }}件起购&nbsp;]</p>
						</div>
					</template>
				</el-row>
				<el-row v-if="boutiqueList.length > 0">
					<h2>精选产品</h2>
					<template v-for="(product, index) in boutiqueList">
						<div
							class="product-list"
							:style="index == 4 || index == 9 ? 'margin-right:0px;' : ''"
							:key="'boutique_' + product.id"
							@click="showProductDetail(product.id)"
						>
							<p style="margin: 0px;">
								<img :src="apiServer + product.main_image.path" :alt="product.name" />
							</p>
							<p class="product-name" :title="product.name">{{ product.name }}</p>
							<p class="product-price">￥{{ product.price / 100 }}</p>
							<p v-if="product.least > 1" class="product-limit">[&nbsp;{{ product.least }}件起购&nbsp;]</p>
						</div>
					</template>
				</el-row>
				<el-row v-if="newStarList.length > 0">
					<h2>新品推荐</h2>
					<template v-for="(product, index) in newStarList">
						<div
							class="product-list"
							:style="index == 4 || index == 9 ? 'margin-right:0px;' : ''"
							:key="'boutique_' + product.id"
							@click="showProductDetail(product.id)"
						>
							<p style="margin: 0px;">
								<img :src="apiServer + product.main_image.path" :alt="product.name" />
							</p>
							<p class="product-name" :title="product.name">{{ product.name }}</p>
							<p class="product-price">￥{{ product.price / 100 }}</p>
							<p v-if="product.least > 1" class="product-limit">[&nbsp;{{ product.least }}件起购&nbsp;]</p>
						</div>
					</template>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
import base from "@/base.js"
import { apiListCarousel } from "@/api/pub/carousel.js"
import { apiListHotSale, apiListBoutique, apiListNewStar } from "@/api/pub/product.js"

export default {
	data() {
		return {
			apiServer: base.apiServer,
			carouselList: [],
			hotSaleList: [],
			boutiqueList: [],
			newStarList: []
		}
	},
	methods: {
		showProductDetail(id) {
			window.open("/product/detail?id=" + id)
		},
		// 获取轮播图
		async listCarousel() {
			try {
				const response = await apiListCarousel()
				if (response.data.code == 200) {
					this.carouselList = response.data.data
				} else {
					console.warn("response>>", response)
					this.$message.error(response.data.message)
				}
			} catch (error) {
				console.error(error)
			}
		},
		// 列出热销的产品
		async listHotSale() {
			let formData = new FormData()
			formData.append("hot_sale", true)
			formData.append("start_time", new Date(0, 1))
			formData.append("end_time", new Date(9999, 1))
			const response = await apiListHotSale(formData)
			if (response.data.code == 200) {
				this.hotSaleList = response.data.data
			} else {
				console.error("response>>", response)
				this.$message.error(response.data.message)
			}
		},
		// 列出精选
		async listBoutique() {
			const response = await apiListBoutique()
			if (response.data.code == 200) {
				this.boutiqueList = response.data.data
			} else {
				console.error("response>>", response)
				this.$message.error(response.data.message)
			}
		},
		// 列出新品
		async listNewStar() {
			const response = await apiListNewStar()
			if (response.data.code == 200) {
				this.newStarList = response.data.data
			} else {
				console.error("response>>", response)
				this.$message.error(response.data.message)
			}
		}
	},
	mounted() {
		this.listCarousel()
		this.listHotSale()
		this.listBoutique()
		this.listNewStar()
	}
}
</script>

<style lang="scss" scoped>
@import "./home.scss";
</style>
