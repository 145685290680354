<template>
	<div style="background-color:#efefef">
		<div class="footer-container">
			<el-row class="footer-icon">
				<el-col :span="6">
					<i class="el-icon-medal"></i>
					东荣制品&nbsp;质量保证
				</el-col>
				<el-col :span="6">
					<i class="el-icon-date"></i>
					7天退货&nbsp;15天换货
				</el-col>
				<el-col :span="6">
					<i class="el-icon-present"></i>
					满99包邮
				</el-col>
				<el-col :span="6">
					<i class="el-icon-umbrella"></i>
					风里雨里&nbsp;一直等你
				</el-col>
			</el-row>
			<el-row style="">
				<el-col :span="16" class="footer-info">
					<div style="float: left;">
						<p><a style="font-weight: bold;">友情链接</a></p>
						<p><a href="http://www.dongrong668.com" target="_blank">企业网站</a></p>
						<p><a href="http://www.dongrong668.com/list_article?type=新闻资讯&nav=新闻资讯" target="_blank">企业新闻</a></p>
					</div>
					<div style="float: left;margin-left:40px;">
						<p><a style="font-weight: bold;">关于我们</a></p>
						<p><a href="http://www.dongrong668.com/about_us?nav=关于我们" target="_blank">公司介绍</a></p>
					</div>
				</el-col>
				<el-col :span="8" class="footer-info">
					<div style="float: right;">
						<p>公司地址：广东省东莞市寮步镇向西工业园</p>
						<p>联系方式：0769-83302002</p>
						<p>邮政编码：523430</p>
					</div>
				</el-col>
			</el-row>
			<div class="footer-copyright">Copyright © {{ copyright }}</div>
		</div>
	</div>
</template>

<script>
import base from "@/base.js"
export default {
	data() {
		return {
			copyright: base.copyright
		}
	},
	methods: {},
	mounted() {}
}
</script>

<style lang="scss" scoped>
@import "./footer.scss";
</style>
