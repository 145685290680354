import axios from "@/api/baseRequest.js"

//增加到购物车
export const apiAddShoppingCart = (token, formData) =>
	axios.post("/api/v1/auth/shopping_cart/add", formData, {
		headers: {
			token: token
		}
	})

//更新购物车项
export const apiUpdateShoppingCart = (token, formData) =>
	axios.post("/api/v1/auth/shopping_cart/update", formData, {
		headers: {
			token: token
		}
	})

//删除购物车列表
export const apiDeleteShoppingCart = (token, formData) =>
	axios.post("/api/v1/auth/shopping_cart/delete_list", formData, {
		headers: {
			token: token
		}
	})

//列出购物车项
export const apiListShoppingCart = (token, paramsObj) =>
	axios.get("/api/v1/auth/shopping_cart/list", {
		headers: {
			token: token
		},
		params: paramsObj
	})

//根据ID列表列出购物车
export const apiListShoppingCartByIdList = (token, formData) =>
	axios.post("/api/v1/auth/shopping_cart/list_by_id_list", formData, {
		headers: {
			token: token
		}
	})
