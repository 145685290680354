import axios from "@/api/baseRequest.js"

//获取分类列表
export const apiListCategory = function() {
	return axios.get("/api/v1/pub/category/list")
}

//列出父类
export const apiListParentCategory = function() {
	return axios.get("/api/v1/pub/category/list_parent_category")
}

//根据home_display列出分类
export const apiListCategoryByHomeDisplay = function(homeDisplay) {
	return axios.get("/api/v1/pub/category/list_by_home_display", {
		params: {
			home_display: homeDisplay
		}
	})
}

//根据ID获取分类
export const apiGetCategoryById = function(id) {
	return axios.get("/api/v1/pub/category/get_by_id", {
		params: {
			id: id
		}
	})
}

//列出产品
export const apiListCategoryByCondition = (formData) => axios.post("/api/v1/pub/category/list_by_condition", formData)
