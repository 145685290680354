<template>
	<div>
		<el-backtop :right="20">
			<div
				style="{
					height: 100%;
					width: 100%;
					background-color: #ffffff;
					border-radius: 6px;
					box-shadow: 0 0 6px rgba(0,0,0, .12);
					text-align: center;
					line-height: 40px;
					color: #1989fa;
      			}"
			>
				<i class="el-icon-caret-top"></i>
			</div>
		</el-backtop>
		<div class="site-topbar">
			<div class="container">
				<div class="topbar">
					<a href="/">商城首页</a>
					<span class="sep">|</span>
					<a href="http://www.dongrong668.com" target="_blank">企业网站</a>
				</div>
				<div class="topbar-cart">
					<el-dropdown>
						<a @mouseenter="listShoppingCart" class="topbar-cart-a" href="/customer/pc/shopping_cart/list">
							<i class="el-icon-shopping-cart-1"></i>购物车({{ totalCount }})
						</a>
						<el-dropdown-menu>
							<div v-if="shoppingCartList.length == 0" style="padding:20px 40px;color: #424242;font-size:smaller">
								您的购物车是空的，赶紧选购吧！
							</div>
							<template v-else>
								<el-table :data="shoppingCartList" style="width: 500px;font-size:small" :show-header="false">
									<el-table-column label="商品缩略图" width="60">
										<template slot-scope="scope">
											<img
												@click="showProductDetail(scope.row.product_id)"
												class="product-image-small"
												:src="apiServer + scope.row.product.main_image.path"
												:alt="scope.row.product.name"
											/>
										</template>
									</el-table-column>
									<el-table-column label="商品名称">
										<template slot-scope="scope">
											<a @click="showProductDetail(scope.row.product_id)" class="product-name">{{ scope.row.product.name }}</a>
										</template>
									</el-table-column>
									<el-table-column prop="product_stock.label" label="规格" width="100"></el-table-column>
									<el-table-column label="单价" width="80">
										<template slot-scope="scope">
											<p style="text-align:right">￥{{ keepTwoDecimal(scope.row.product_stock.current_price / 100) }}</p>
										</template>
									</el-table-column>
									<el-table-column label="数量" width="60">
										<template slot-scope="scope">X&nbsp;{{ scope.row.count }} </template>
									</el-table-column>
									<el-table-column label="操作" width="40" style="text-align:center">
										<template slot-scope="scope">
											<el-button
												@click="deleteShoppingCart(scope.row.id)"
												type="text"
												icon="el-icon-error"
												style="color:#ff6700"
											></el-button>
										</template>
									</el-table-column>
								</el-table>
								<el-row style="padding-top:8px;padding-right:4px;text-align:right">
									<p style="float:left;margin:10px 20px;">
										<span style="font-size:small">共 {{ totalCount }} 件商品</span>
										<span style="color:red;margin-left:6px;">￥{{ keepTwoDecimal(totalAmount / 100) }}</span>
									</p>
									<el-button @click="showShoppingCartList" type="danger">去购物车结算</el-button>
								</el-row>
							</template>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="topbar-info">
					<a class="link" href="/customer/pc/order/home">我的订单</a>
					<el-menu
						:default-active="userActiveIndex"
						mode="horizontal"
						background-color="#333333"
						text-color="#b0b0b0"
						active-text-color="#ffffff"
						class="topbar-user"
						style="float:right"
						@select="handleSelect"
						ref="elMenuUser"
					>
						<el-submenu index="2" class="topbar-user" id="head_user">
							<template slot="title" class="topbar-user">{{ user.name }}</template>
							<el-menu-item index="2-1" class="topbar-user" @click="openUserHome">个人中心</el-menu-item>
							<el-menu-item index="2-3" class="topbar-user" @click="signOut">退出</el-menu-item>
						</el-submenu>
					</el-menu>
					<div style="float:right" ref="loginContainer">
						<span class="sep" style="float:right">|</span>
						<a class="link" @click="register">注册</a>
						<span class="sep" style="float:right">|</span>
						<a class="link" @click="login">登录</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import base from "@/base.js"
import { apiListShoppingCart, apiDeleteShoppingCart } from "@/api/auth/customer/shoppingCart.js"
export default {
	data() {
		return {
			apiServer: base.apiServer,
			user: {
				name: ""
			},
			userActiveIndex: "",
			shoppingCartList: [],
			totalCount: 0,
			totalAmount: 0
		}
	},
	methods: {
		showProductDetail(productId) {
			window.location.href = "/product/detail?id=" + productId
		},
		showShoppingCartList() {
			window.location.href = "/customer/pc/shopping_cart/list"
		},
		keepTwoDecimal(value) {
			var value = Math.round(parseFloat(value) * 100) / 100
			var xsd = value.toString().split(".")
			if (xsd.length == 1) {
				value = value.toString() + ".00"
				return value
			}
			if (xsd.length > 1) {
				if (xsd[1].length < 2) {
					value = value.toString() + "0"
				}
				return value
			}
		},
		login() {
			window.location.href = "/login?target=" + window.location.href
		},
		register() {
			window.location.href = "/register?target=" + window.location.href
		},
		//退出登录
		async signOut() {
			//清除token
			await this.$store.dispatch("clearToken")
			//本地删除token
			//localStorage.removeItem("token")
			sessionStorage.removeItem("token")

			//删除user
			await this.$store.dispatch("clearUser")
			//本地删除user
			//localStorage.removeItem("user")
			sessionStorage.removeItem("user")

			//刷新页面
			location.reload()
		},
		openUserHome() {
			window.location.href = "/customer/pc/user/home"
		},
		handleSelect(key, keyPath) {},
		getUser() {
			if (this.$store.state.user == "") {
				//未登录
				this.$refs.loginContainer.style.display = ""
				this.$refs.elMenuUser.$el.style.display = "none"
			} else {
				//已登录
				this.user = JSON.parse(this.$store.state.user)
				this.$refs.loginContainer.style.display = "none"
				this.$refs.elMenuUser.$el.style.display = ""
			}

			//设置样式
			let dom = document.getElementById("head_user")
			dom.children[0].style.height = "40px"
			dom.children[0].style.lineHeight = "40px"
			let ul = dom.getElementsByTagName("ul")
			ul[0].style.width = "150px"
			ul[0].style.minWidth = "150px"
			ul[0].style.marginTop = "0px"
		},
		handleCount() {
			this.totalCount = 0
			this.totalAmount = 0
			for (let i = 0; i < this.shoppingCartList.length; i++) {
				this.totalCount += this.shoppingCartList[i].count
				this.totalAmount += this.shoppingCartList[i].count * this.shoppingCartList[i].product_stock.current_price
			}
		},
		// 获取购物车列表
		async listShoppingCart() {
			try {
				if (this.$store.state.user == "") {
					//未登录
					return
				}

				let paramsObj = {
					start: 0,
					limit: 1000
				}
				const response = await apiListShoppingCart(this.$store.state.token, paramsObj)
				if (response.data.code == 200) {
					this.shoppingCartList = response.data.data.shoppingCartList
					this.handleCount()
				} else {
					this.$message.error(response.data.message || response.data.exception)
				}
			} catch (error) {
				console.error(error)
			}
		},
		deleteShoppingCart(cartId) {
			let idList = []
			idList.push(cartId)
			var formData = new FormData()
			formData.append("idList", JSON.stringify(idList))
			//调用接口
			apiDeleteShoppingCart(this.$store.state.token, formData).then((response) => {
				if (response.data.code === 200) {
					//删除成功
					//刷新购物车
					this.listShoppingCart()
				} else {
					console.error(response)
					this.$message.error(response.data.message)
				}
			})
		}
	},
	mounted() {
		this.getUser()
	}
}
</script>

<style lang="scss" scoped>
@import "./header.scss";
</style>
<style>
/* .el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
	min-width: 116px;
	width: 116px;
} */
.product-image-small {
	width: 50px;
	height: 50px;
}
</style>
