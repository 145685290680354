<template>
	<div>
		<div class="site-header">
			<div class="container">
				<div class="logo">
					<a href="/">
						<img :src="logo" style="width: 70px;height: 70px;margin-top: 10px;" />
					</a>
				</div>
				<div class="header-nav">
					<ul class="nav-list">
						<li>
							<el-menu :default-active="categoryActiveIndex" mode="horizontal" text-color="#333" @select="handleSelect">
								<el-submenu index="el-submenu">
									<template slot="title">
										<a class="nav-list-a" href="/product/list">全部商品分类</a>
									</template>
									<el-submenu :index="category.id.toString()" v-for="category in categoryList" :key="category.id">
										<template slot="title">{{ category.label }}</template>
										<el-menu-item
											:index="subcategory.id.toString()"
											v-for="subcategory in category.category_list"
											:key="subcategory.id"
											@click="showProduct(subcategory.id)"
										>
											{{ subcategory.label }}
										</el-menu-item>
									</el-submenu>
								</el-submenu>
							</el-menu>
						</li>
						<li
							class="nav-list-li"
							v-for="category in homeDisplayCategoryList"
							@click="showProduct(category.id)"
							:key="'home_display_' + category.id"
						>
							{{ category.label }}
						</li>
					</ul>
				</div>
				<div class="header-search">
					<el-button icon="el-icon-search" class="search-button" @click="searchProductByName"></el-button>
					<input
						v-model="condition.name"
						type="text"
						autocomplete="off"
						placeholder="搜索产品"
						class="el-input__inner search-input"
						@blur="searchProductByName()"
						onkeydown="if(event.keyCode==13){blur();}"
					/>
					<!-- @keyup.enter="$event.target.blur" -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import logo from "@/assets/images/logo.png"
import { apiListCategory, apiListCategoryByHomeDisplay } from "@/api/pub/category.js"

export default {
	data() {
		return {
			logo,
			categoryActiveIndex: "",
			condition: {
				name: ""
			},
			categoryList: [],
			homeDisplayCategoryList: []
		}
	},
	methods: {
		handleSelect(key, keyPath) {
			console.log(key, keyPath)
		},
		searchProductByName() {
			/* let searchName = this.$refs.inputSearch.value
			this.condition.name = searchName */
			if (this.condition.name != "") {
				window.location.href = "/product/list?name=" + this.condition.name
				//this.$router.push({ path: "/product/list?name=" + this.condition.name })
				//this.$router.push({ path: "/product/list", query: { name: this.condition.name } })
			}
		},
		showProduct(category_id) {
			window.location.href = "/product/list?category_id=" + category_id
		},
		getCondition() {
			this.condition.name = typeof this.$route.query.name == "undefined" ? "" : this.$route.query.name
		},
		// 列出分类
		async listCategory() {
			try {
				const response = await apiListCategory()
				//console.log(response);
				if (response.data.code == 200) {
					this.categoryList = response.data.data
					//console.log(this.categoryList);
					//遍历categoryList修改属性以适应el-cascader组件要求
					for (let i = 0; i < this.categoryList.length; i++) {
						this.categoryList[i].value = this.categoryList[i].id
						if (this.categoryList[i].category_list.length > 0) {
							this.categoryList[i].children = this.categoryList[i].category_list
							for (var j = 0; j < this.categoryList[i].children.length; j++) {
								this.categoryList[i].children[j].value = this.categoryList[i].children[j].id
							}
						}
					}
				} else {
					console.log("response>>", response)
					this.$message.error(response.data.message)
				}
			} catch (error) {
				console.log(error)
			}
		},
		// 列出首页显示的分类
		async listHomeCategoryList() {
			const response = await apiListCategoryByHomeDisplay(true)
			if (response.data.code == 200) {
				this.homeDisplayCategoryList = response.data.data
				//console.log(this.homeDisplayCategoryList)
			} else {
				console.log("response>>", response)
				this.$message.error(response.data.message)
			}
		}
	},
	mounted() {
		this.getCondition()
		this.listCategory()
		this.listHomeCategoryList()
	}
}
</script>

<style lang="scss" scoped>
@import "./header.scss";
</style>
<style>
/* .el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
	min-width: 116px;
	width: 116px;
} */
</style>
