<template>
	<div class="veadmin-header-container">
		<a class="veadmin-header-logo" href="/aboutHui.shtml">东荣商城 [后台] </a>
		<span class="veadmin-header-version">{{ version }}</span>
		<div class="veadmin-header-datetime">
			<span style="color:#fff;margin-left: 14px;">当前时间：{{ getTime(date) }}</span>
			<span style="font-size: x-small;;color:#cccccc;margin-left: 4px;">{{ getCustomDate(date) }}</span>
		</div>
		<div class="veadmin-header-menu">
			<ul>
				<li><span style="padding-left:10px;">你好！</span></li>
				<li v-for="(item, count) in headerData" :key="count.toString()" @click="openTab(item.label, item.url, item.open_tab)">
					<i :class="item.icon"></i>
					<span>{{ item.label }}</span>
				</li>
				<li @click="openTab('信息中心', '/admin/message_list', true)">
					<i class="el-icon-message"></i>
					<span>信息中心</span>
				</li>
				<li @click="signOut()">
					<i class="el-icon-switch-button"></i>
					<span>退出</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { GetTime, GetCustomDate } from "@/assets/ve-admin/TimeUtils.js"
import Base from "@/base.js"

export default {
	data() {
		return {
			version: Base.version,
			date: new Date(),
			headerData: [
				{
					label: "小明",
					url: "/admin/user_info",
					icon: "el-icon-user-solid",
					open_tab: false
				},
				{
					label: "操作日志",
					url: "/admin/operation_log",
					icon: "el-icon-thumb",
					open_tab: true
				}
			]
		}
	},
	methods: {
		getTime(date) {
			return GetTime(date)
		},
		getCustomDate(date) {
			return GetCustomDate(date)
		},
		openTab(label, url, open_tab) {
			this.$parent.openTab(label, url, open_tab)
		},
		//退出登录
		async signOut() {
			//清除token
			await this.$store.dispatch("clearToken")
			//本地删除token
			//localStorage.removeItem("token")
			sessionStorage.removeItem("token")

			//删除user
			await this.$store.dispatch("clearUser")
			//本地删除user
			//localStorage.removeItem("user")
			sessionStorage.removeItem("user")

			//刷新页面
			location.reload()
		},
		getUser() {
			this.user = JSON.parse(this.$store.state.user)
			//非法访问，跳转至首页
			if (this.user.type != "waiter" && this.user.type && "salesperson" && this.user.type != "admin") {
				location.href = "/"
			}
		}
	},
	mounted() {
		//更新时间
		let _this = this // 声明一个变量指向Vue实例this，保证作用域一致
		this.timer = setInterval(() => {
			_this.date = new Date() // 修改数据date
		}, 1000)

		this.getUser()
	},
	beforeDestroy() {
		if (this.timer) {
			clearInterval(this.timer) // 在Vue实例销毁前，清除我们的定时器
		}
	}
}
</script>

<style lang="scss" scoped></style>
