export default [
	{
		path: "/customer/pc/shopping_cart/list",
		name: "listShoppingCart",
		component: () => import("@/views/auth/pc/customer/shopping_cart/List.vue"),
		meta: {
			title: "我的购物车 - 东荣商城",
			requiresAuth: true
		}
	},
	{
		path: "/customer/pc/order/confirm",
		name: "confirmOrder",
		component: () => import("@/views/auth/pc/customer/order/Confirm.vue"),
		meta: {
			title: "确认订单 - 东荣商城",
			requiresAuth: true
		}
	},
	{
		path: "/customer/pc/order/payment",
		name: "orderPayment",
		component: () => import("@/views/auth/pc/customer/order/Payment.vue"),
		meta: {
			title: "订单支付 - 东荣商城",
			requiresAuth: true
		}
	},
	{
		path: "/customer/pc/product/snapshot",
		name: "productSnapshot",
		component: () => import("@/views/auth/pc/customer/order/Snapshot.vue"),
		meta: {
			title: "产品快照 - 东荣商城",
			requiresAuth: true
		}
	},
	{
		path: "/customer/pc/",
		component: () => import("@/views/auth/pc/customer/Index.vue"),
		meta: {
			requiresAuth: true
		},
		children: [
			{
				path: "user/home",
				component: () => import("@/views/auth/pc/customer/user/Home.vue"),
				meta: {
					title: "个人中心 - 东荣商城",
					requiresAuth: true
				}
			},
			{
				path: "user/edit_phone",
				component: () => import("@/views/auth/pc/customer/user/EditPhone.vue"),
				meta: {
					title: "修改手机号码 - 东荣商城",
					requiresAuth: true
				}
			},
			{
				path: "user/edit_email",
				component: () => import("@/views/auth/pc/customer/user/EditEmail.vue"),
				meta: {
					title: "修改邮箱 - 东荣商城",
					requiresAuth: true
				}
			},
			{
				path: "user/edit_password",
				component: () => import("@/views/auth/pc/customer/user/EditPassword.vue"),
				meta: {
					title: "修改密码 - 东荣商城",
					requiresAuth: true
				}
			},
			{
				path: "address/list",
				component: () => import("@/views/auth/pc/customer/address/List.vue"),
				meta: {
					title: "收货地址 - 东荣商城",
					requiresAuth: true
				}
			},
			{
				path: "address/add",
				component: () => import("@/views/auth/pc/customer/address/Add.vue"),
				meta: {
					title: "新增收货地址 - 东荣商城",
					requiresAuth: true
				}
			},
			{
				path: "address/edit",
				component: () => import("@/views/auth/pc/customer/address/Edit.vue"),
				meta: {
					title: "修改收货地址 - 东荣商城",
					requiresAuth: true
				}
			},
			{
				path: "order/home",
				component: () => import("@/views/auth/pc/customer/order/Home.vue"),
				meta: {
					title: "订单中心 - 东荣商城",
					requiresAuth: true
				}
			},
			{
				path: "order/detail",
				component: () => import("@/views/auth/pc/customer/order/Detail.vue"),
				meta: {
					title: "订单详情 - 东荣商城",
					requiresAuth: true
				}
			},
			{
				path: "order/return",
				component: () => import("@/views/auth/pc/customer/order/Return.vue"),
				meta: {
					title: "退换货记录 - 东荣商城",
					requiresAuth: true
				}
			},
			{
				path: "order/refund",
				component: () => import("@/views/auth/pc/customer/order/Refund.vue"),
				meta: {
					title: "退款记录 - 东荣商城",
					requiresAuth: true
				}
			},
			{
				path: "order/comment",
				component: () => import("@/views/auth/pc/customer/order/Comment.vue"),
				meta: {
					title: "评论列表 - 东荣商城",
					requiresAuth: true
				}
			}
		]
	}
]
