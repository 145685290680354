import Index from "@/views/pub/pc/Index.vue"
import Home from "@/views/pub/pc/Home.vue"

export default [
	{
		path: "/",
		component: Index,
		children: [
			{
				path: "",
				component: Home
			},
			{
				path: "product/list",
				component: () => import("@/views/pub/pc/product/List.vue")
			},
			{
				path: "product/detail",
				component: () => import("@/views/pub/pc/product/Detail.vue")
			}
		]
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("@/views/pub/pc/user/Login.vue"),
		meta: {
			title: "东荣账号 - 登录"
		}
	},
	{
		path: "/register",
		name: "Register",
		component: () => import("@/views/pub/pc/user/Register.vue"),
		meta: {
			title: "东荣账号 - 注册"
		}
	},
	{
		path: "/reset_password",
		name: "ResetPassword",
		component: () => import("@/views/pub/pc/user/ResetPassword.vue"),
		meta: {
			title: "东荣账号 - 找回密码"
		}
	},
	{
		path: "/agreement",
		name: "Agreement",
		component: () => import("@/views/pub/pc/user/Agreement.vue"),
		meta: {
			title: "东荣账号用户协议"
		}
	},
	{
		path: "/privacy",
		name: "Privacy",
		component: () => import("@/views/pub/pc/user/Privacy.vue"),
		meta: {
			title: "东荣账号隐私政策"
		}
	}
]
