import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
// 引入第三方包：ElementUI
import "element-ui/lib/theme-chalk/index.css"
import ElementUI from "element-ui"
// 引入ve-admin样式
import "@/assets/ve-admin/ve-admin.scss"

Vue.use(ElementUI)
Vue.config.productionTip = false

//路由拦截，拦截全部路由，每次操作路由都是被拦截进行判断
router.beforeEach((to, from, next) => {
	//设置默认标题
	document.title = "东荣 - 不忘初心，再创辉煌"
	//路由内设置了标题则使用路由设置的标题
	if (to.meta.title) {
		document.title = to.meta.title
	}

	const token = localStorage.getItem("token") || sessionStorage.getItem("token")
	//筛选需要传token的路由，匹配route里面需要登录的路径，如果匹配到就是true
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		//根据token是否有，判断是否需要调到登录页面
		if (token) {
			next()
		} else {
			next({ path: "/login?target=" + window.location.href })
		}
	} else {
		next()
	}
})

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount("#app")
