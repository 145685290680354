import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: localStorage.getItem("token") || sessionStorage.getItem("token") || "",
		user: localStorage.getItem("user") || sessionStorage.getItem("user") || ""
	},
	//同步修改state里面的值
	mutations: {
		synchronousSetToken: (state, token) => {
			state.token = token
		},
		synchronousSetUser: (state, user) => {
			state.user = user
		}
	},
	//异步调用mutations里面的方法
	//contxt.commit 利用上下文触发mutations某个方法
	//vue代码里面 this.$store.dispatch触发action里面的定义的方法
	actions: {
		setToken(context, token) {
			context.commit("synchronousSetToken", token)
		},
		clearToken(context) {
			context.commit("synchronousSetToken", "")
		},
		setUser(context, user) {
			context.commit("synchronousSetUser", user)
		},
		clearUser(context) {
			context.commit("synchronousSetUser", "")
		}
	},
	modules: {}
})
