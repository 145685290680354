import axios from "@/api/baseRequest.js"

//列出产品
export const apiListProduct = (formData) => axios.post("/api/v1/pub/product/list", formData)

//获取热销产品
export const apiListHotSale = () => axios.get("/api/v1/pub/product/list_hot_sale")

//获取精品列表
export const apiListBoutique = () => axios.get("/api/v1/pub/product/list_boutique")

//获取新品列表
export const apiListNewStar = () => axios.get("/api/v1/pub/product/list_new_star")

//根据ID获取产品
export const apiGetProductById = function(id) {
	return axios.get("/api/v1/pub/product/get_by_id", {
		params: {
			id: id
		}
	})
}
